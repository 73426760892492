@font-face {
  font-family: "Varela Regular";
  src: url("./fonts/Varela-Regular.ttf");
  }

.wrapper {
  display:flex;
}

body {
  background-color: #44A0E9;
  font-family:'Segoe UI', 'Varela Regular';
  color: white;
  margin: 0;
  padding: 0;
}

body a {
  color: white;
  text-decoration: none;
}

body a:hover {
  color:black;
}

.left {
  width: 25%;
  font-size: 45px;
  border-right: 1px solid black;
  height: 100vh;
}

.left p {
  padding-left: 10px;
}

.right {
  width:75%;
  margin: 15px 0px 0px 0px;
  text-align: center;
  font-size: 45px;
}

.main-para {
  font-weight: bold;
  margin-top: 75px;
}

.gif-image {
  text-align: center;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

h2 {
  color: orangered;
  font-size: 70px;
  margin-bottom: 5px;
}

.social-media-nav {
  list-style-type: none;
  display: inline-table;
  margin-top: 5px;
}

li {
  float:left;
  margin: 5px;
}

li:hover {
  transform: scale(1.2);
}

p {
  text-align: center;
}

.mobile-small-left {
  display: none;
  visibility: hidden;
}

@media only screen and (max-width: 1122px)  {
  .right h1{
    font-size:65px;
  }

  h2 {
    font-size: 55px;
  }

  .left p {
    font-size: 35px;
  }

  
}

@media only screen and (max-width: 845px)  {
  .right {
    width: 100%;
  }

  .left {
    display: none;
    visibility: none;
  }

  .main-para {
    margin-top: 40px;
  }

  h2 {
    font-size: 48px;
  }

  .right p {
    font-size: 35px;
  }
  .mobile-small-left {
    display: initial;
    visibility: visible;
  }
  
}

